<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-8">
            <card-content-publication
              :publicationContent="publication.description"
              :modeEdit="modeEdit"
              @set-value="$emit('set-value', $event)"
              ref="contentPublication"
            />
          </div>
          <div class="column is-4">
            <div class="columns is-multiline">
              <div class="column is-12">
                <card-upload-file
                  titleCard="PORTADA"
                  :previewImage="previewImageCover"
                  :setFile="setFileCover"
                  :modeEdit="modeEdit"
                  validate="required|ext:jpeg,jpg,png|size:1000"
                  accept=".jpeg,.jpg,.png,"
                />
              </div>
              <div class="column is-12">
                <card-general-informatio-publication
                  :modeEdit="modeEdit"
                  :categoryId="publication.categoryId"
                  :author="publication.author"
                  :creationDate="publication.creationDate"
                  :status="publication.status"
                  :createdMode="true"
                  :categoriesList="categoriesList"
                  @set-value="$emit('set-value', $event)"
                  :loadingCat="loadingCat"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PublicationMainAdd',
  inject: ['$validator'],
  components: {
    CardUploadFile: () => import('@/components/UI/CardUploadFile.vue'),
    CardContentPublication: () => import('@/components/Publications/CardContentPublication.vue'),
    CardGeneralInformatioPublication: () => import('@/components/Publications/CardGeneralInformatioPublication.vue')
  },
  data () {
    return {
      modeEdit: true,
      previewImageCover: null
    }
  },
  props: {
    publication: {
      type: Object,
      default: () => {}
    },
    categoriesList: {
      type: Array,
      default: () => []
    },
    loadingCat: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async setFileCover (event) {
      if (event) {
        const [file] = event.target.files
        const imageFile = { key: event.target.name, file }
        // Preview
        if (file) {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = e => {
            this.previewImageCover = e.target.result
          }
          this.$emit('set-image', imageFile)
        }
      }
    }
  },
  beforeMount () {
    this.previewImageCover = this.publication.coverImage
  }
}
</script>

<style lang="scss" scoped>

</style>
